import React, { Component } from 'react';
import './MarkdownViewer.scss';

export class MarkdownViewer extends Component {
    static displayName = MarkdownViewer.name;

    render() {
        return (
            <div className="markdown-viewer" dangerouslySetInnerHTML={{ __html: this.props.html }}></div>
        );
    }
}