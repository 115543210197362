import React, { Component } from 'react';

export class TocHeaderList extends Component {
    static displayName = TocHeaderList.name;

    render() {
        return (
            <ul>
                {this.props.items && this.props.items.map(item =>
                    <li key={item.id}>
                        <a href={'#' + item.id} className="nav-link">{item.label}</a>
                        {item.children && item.children.length > 0 &&
                            <TocHeaderList items={item.children}></TocHeaderList>
                        }
                    </li>
                )}
            </ul>
        );
    }
}