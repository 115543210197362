import React, { Component } from 'react';
import { Loading } from './Loading';
import { API } from './../API';
import Error from './Error';

export class GoToArticle extends Component {
    static displayName = GoToArticle.name;

    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    async componentDidMount() {
        const { id } = this.props.match.params;

        var result = await API.getArticleById(id);
        if (result.error) {
            this.setState({ error: result.error });
        } else {
            // FIXME: we should pass data to Article component instead of doing twice the job
            this.props.history.replace('/articles/' + result.data.article.slug);
        }
    }

    render() {
        if (this.state.error) return <Error error={this.state.error} />;
        else if (this.state.loading) return <Loading />;
        else return null;
    }
}
