import React, { Component } from 'react';
import { Spinner } from 'reactstrap';

export class Loading extends Component {
    static displayName = Loading.name;

    render() {
        return (
            <div className="d-flex justify-content-center pt-4">
                <Spinner type="border" color="primary" />
            </div>
        );
    }
}