import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Loading } from './Loading';
import { API } from './../API';
import Error from './Error';

import './Books.scss';

export class Books extends Component {
    static displayName = Books.name;

    constructor(props) {
        super(props);
        this.state = { books: [], loading: true };
    }

    async componentDidMount() {
        document.title = "Aide Xelya";
        await this.populateData();
        await this.populateLastArticlesData();
    }

    async populateData() {
        var result = await API.getBooks();
        if (result.error) {
            this.setState({ error: result.error });
        } else {
            this.setState({ books: result.data, loading: false, error: null });
        }
    }

    async populateLastArticlesData() {
        var result = await API.getLastArticles(9);
        if (result.error) {
            this.setState({ error: result.error });
        } else {
            this.setState({ lastArticles: result.data, loading: false, error: null });
        }
    }

    renderBooks() {
        return <div className="row row-cols-1">
            {this.state.books && this.state.books.length > 0 &&
                <div className="px-3 py-4">
                    <h4 className="mb-4 mx-3">Livres</h4>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 m-1">
                        {this.state.books.map(book =>
                            <div className="col p-3" key={book.id}>
                                <Link to={`/articles/${book.slug}`} className="card-link">
                                    <div className="card shadow-sm h-100 book-card" style={{ borderLeftColor: book.color }}>
                                        <div className="card-body">
                                            <h5 className="card-title">{book.title}</h5>
                                            <p className="card-text text-muted">{book.teaser}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            }
            {/*{this.state.lastArticles && this.state.lastArticles.length > 0 &&*/}
            {/*    <div className="px-3 pt-5 pb-4 bg-light">*/}
            {/*        <h4 className="mb-4 mx-3">Articles recommandés</h4>*/}
            {/*        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 m-1">*/}
            {/*            {this.state.lastArticles.map(article =>*/}
            {/*                <div className="col p-2" key={article.id}>*/}
            {/*                    <Link to={`/articles/${article.slug}`} className="card-link">*/}
            {/*                        <div className="card shadow-sm h-100 p-2">*/}
            {/*                            <table>*/}
            {/*                                <tr>*/}
            {/*                                    <td>*/}
            {/*                                        <i class="bi bi-file-text-fill mr-2 h1 text-warning"></i>*/}
            {/*                                    </td>*/}
            {/*                                    <td width="100%" className="p-0">*/}
            {/*                                        <div className="card-title m-0">{article.title}</div>*/}
            {/*                                        <small className="card-text text-success d-block lh-1">{article.slug}</small>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                            </table>*/}
            {/*                        </div>*/}
            {/*                    </Link>*/}
            {/*                </div>*/}
            {/*            )}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            {this.state.lastArticles && this.state.lastArticles.length > 0 &&
                <div className="px-3 py-4 bg-light">
                    <h4 className="mb-4 mx-3">Derniers articles</h4>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 m-1">
                        {this.state.lastArticles.map(article =>
                            <div className="col p-2" key={article.id}>
                                <Link to={`/articles/${article.slug}`} className="card-link">
                                    <div className="card shadow-sm h-100 p-2">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pl-1">
                                                        <i className="bi bi-file-text-fill mr-2 h1 text-primary"></i>
                                                    </td>
                                                    <td width="100%" className="py-0 px-1">
                                                        <div className="card-title m-0">{article.title}</div>
                                                        <div><small className="card-text text-success d-block lh-1">{article.slug}</small></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>;
    }

    render() {
        if (this.state.error) return <Error error={this.state.error} />;
        else if (this.state.loading) return <Loading />;
        else return <div>{this.renderBooks()}</div>;
    }
}
