import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Link } from "react-router-dom";
import { Loading } from './Loading';
import { API } from './../API';
import { UserSessionService } from './../UserSessionService';
import Error from './Error';

import './Search.scss';

export class Search extends Component {
    static displayName = Search.name;

    query = '';
    pageSize = 10;
    pageIndex = 0;
    selectedService = '';
    unListenHistory = null;

    constructor(props) {
        super(props);
        this.state = { results: [], loading: true };
    }

    async componentDidMount() {
        this.setParams(this.props.location);
        await this.search();

        this.unListenHistory = this.props.history.listen(async (location, action) => {
            this.setParams(location);
            await this.search();
        });
    }

    componentWillUnmount() {
        this.unListenHistory();
    }

    setParams(location) {
        var params = new URLSearchParams(location.search);
        this.query = params.get("q") ?? "";
        this.pageIndex = parseInt(params.get("p") ?? "0", 10);
        this.selectedService = params.get("s") ?? "";
    }

    refreshUrl() {
        this.props.history.replace('/search?q=' + encodeURIComponent(this.query) + '&s=' + encodeURIComponent(this.selectedService) + '&p=' + encodeURIComponent(this.pageIndex));
    }

    async previous(event) {
        this.pageIndex = this.pageIndex - 1;
        this.refreshUrl();
    }

    async next(event) {
        this.pageIndex = this.pageIndex + 1;
        this.refreshUrl();
    }

    async changeService(service) {
        this.pageIndex = 0;
        this.selectedService = service;
        this.refreshUrl();
    }

    async search() {
        document.title = "Recherche \"" + this.query + "\" | Aide Xelya";

        if (!this.query || !this.query.length) {
            this.setState({ hitCount: 0, results: [], loading: false });
        } else {

            var result = await API.search(this.query, this.pageIndex * this.pageSize, this.pageSize, this.selectedService);
            if (result.error) {
                this.setState({ error: result.error });
            } else {

                var user = UserSessionService.get();
                var serviceNames = {};
                if (user && user.services) {
                    user.services.forEach((o) => {
                        serviceNames[o.id] = o.displayName;
                    });
                }

                var servicesAggs = [{
                    key: '',
                    label: 'Tous',
                    docCount: result.data.aggregations.services.sumOtherDocCount
                }];

                result.data.aggregations.services.buckets.forEach((o) => {
                    servicesAggs.push({ key: o.key, label: serviceNames[o.key] ?? 'N/A', docCount: o.docCount });
                    servicesAggs[0].docCount += o.docCount;
                });

                this.setState({
                    hitCount: result.data.hitCount,
                    took: result.data.took,
                    results: result.data.results,
                    aggregations: { services: servicesAggs },
                    loading: false,
                    error: null
                });
            }
        }
    }

    renderResults() {
        var filterItemCssClass = 'list-group-item list-group-item-action d-flex justify-content-between align-items-center search-filter-item';
        var filterItemActiveCssClass = 'list-group-item list-group-item-action d-flex justify-content-between align-items-center active search-filter-item';
        var filterBadgeCssClass = 'badge badge-primary badge-pill';
        var filterBadgeActiveCssClass = 'badge badge-light badge-pill';

        if (this.state.hitCount === 0) {
            return <Container fluid>
                <div className="py-3">
                    <div>
                        <h2 className="text-muted text-center my-4"><i className="bi bi-emoji-frown"></i></h2>
                        <h3 className="text-center my-4">Aucun résultat pour "{this.query}"</h3>
                        <h5 className="text-muted text-center my-4">Désolé, nous n'avons rien trouvé qui corresponde à votre recherche</h5>
                    </div>
                </div>
            </Container>;
        } else {
            return <Container fluid>
                <div className="py-3">
                    <div className="row">
                        <div className="col-3">
                            <div className="px-4 py-3"><strong>Service</strong></div>
                            <div className="list-group px-4">
                                {this.state.aggregations.services.map(bucket =>
                                    <button className={this.selectedService === bucket.key ? filterItemActiveCssClass : filterItemCssClass}
                                        key={bucket.key}
                                        onClick={(e) => this.changeService(bucket.key)}>
                                        {bucket.label}
                                        <span className={this.selectedService === bucket.key ? filterBadgeActiveCssClass : filterBadgeCssClass}>{bucket.docCount}</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="col-6 py-4">
                            <small className="text-muted">{this.pageIndex * this.pageSize + 1}-{(((this.pageIndex + 1) * this.pageSize) > this.state.hitCount ? this.state.hitCount : (this.pageIndex + 1) * this.pageSize)} sur {this.state.hitCount} résultats</small>
                            <small className="text-muted"> ({this.state.took / 1000} secondes)</small>
                            <div className="py-4">
                                {this.state.results.map(result =>
                                    <div className="py-2" key={result.source.slug}>
                                        <Link className="search-result-link" to={`/articles/${result.source.slug}`}>{result.source.title}</Link><br />
                                        <div className="search-result-details">
                                            <div><small className="text-success">{result.source.slug}</small></div>
                                            {result.highlight && result.highlight['bodySearchable'] && result.highlight['bodySearchable'].length > 0 &&
                                                <small dangerouslySetInnerHTML={{ __html: result.highlight['bodySearchable'] }}></small>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                            {this.state.hitCount > this.pageSize &&
                                <div className="py-4 d-flex justify-content-between">
                                    <button type="button"
                                        disabled={this.pageIndex === 0}
                                        className="btn btn-outline-primary"
                                        onClick={(event) => this.previous(event)}>
                                        <i className="bi bi-arrow-left mr-2"></i>
                                        Précédent
                                    </button>
                                    <button type="button"
                                        disabled={(this.pageIndex + 1) * this.pageSize >= this.state.hitCount}
                                        className="btn btn-outline-primary"
                                        onClick={(event) => this.next(event)}>
                                        Suivant
                                        <i className="bi bi-arrow-right ml-2"></i>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Container>;
        }
    }

    render() {
        if (this.state.error) return <Error error={this.state.error} />;
        else if (this.state.loading) return <Loading />;
        else return <div>{this.renderResults()}</div>;
    }
}
