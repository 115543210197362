import React, { Component } from 'react';
import './ArticleBanner.scss';

export class ArticleBanner extends Component {
    static displayName = ArticleBanner.name;

    getReadTime() {
        if (this.props.article && this.props.article.body) {
            var words = this.props.article.body.split(' ').length;
            return Math.ceil(words / 200);
        } else {
            return 0;
        }
    }

    getMTime() {
        if (this.props.article && this.props.article.mTime) {
            return new Date(this.props.article.mTime).toLocaleDateString();
        } else {
            return null;
        }
    }

    round(value, precision) {
        return +(Math.round(value + "e+" + precision) + "e-" + precision);
    }

    getViewsCount() {
        if (this.props.article) {
            var views = this.props.article.views;
            if (views > 1000000) {
                return this.round(views / 1000000.0, 2).toLocaleString() + "M";
            } else if (views > 1000) {
                return this.round(views / 1000.0, 2).toLocaleString() + "K";
            } else {
                return views;
            }
        } else {
            return 0;
        }
    }

    render() {

        let readTime = this.getReadTime();
        let viewsCount = this.getViewsCount();

        return (
            <div className="article-banner /*d-flex justify-content-between*/">
                <span>
                    <span className="article-banner-item">
                        <span className="text-muted">Dernière modification le {this.getMTime()}</span>
                    </span>
                    <span className="article-banner-item">
                        <i className="bi bi-clock"></i>
                        <span className="text-muted">{readTime} {readTime > 1 ? "minutes" : "minute"} de lecture</span>
                    </span>
                    <span className="article-banner-item">
                        <i className="bi bi-eye"></i>
                        <span className="text-muted">{viewsCount} {viewsCount > 1 ? "vues" : "vue"}</span>
                    </span>
                </span>
            </div>
        );
    }
}