import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './ArticleNav.scss';

export class ArticleNav extends Component {
    static displayName = ArticleNav.name;

    render() {
        const { previous, next } = this.props;
        if (!previous && !next) return null;

        return (
            <div className="article-nav d-print-none">
                {previous &&
                    <Link className="btn btn-outline-primary previous float-left text-left" to={`/articles/${previous.slug}`}>
                        <div className="pb-2 font-weight-bold">
                            <i className="bi bi-arrow-left mr-2"></i>
                            <span>Précédent</span>
                        </div>
                        <div>{previous.title}</div>
                    </Link>
                }
                {next &&
                    <Link className="btn btn-outline-primary next float-right text-right" to={`/articles/${next.slug}`}>
                        <div className="pb-2 font-weight-bold">
                            <span>Suivant</span>
                            <i className="bi bi-arrow-right ml-2"></i>
                        </div>
                        <div>{next.title}</div>
                    </Link>
                }
                <div className="clearfix"></div>
            </div>
        );
    }
}