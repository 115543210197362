import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { API } from './../API';
import { UserSessionService } from './../UserSessionService';
import Error from './Error';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        var result = await API.getUserSession();
        if (result.error) {
            this.setState({ error: result.error });
        } else {
            UserSessionService.set(result.data);
            this.setState({ user: result.data, error: null });
        }
    }

    render() {
        if (this.state.error) {
            return <Error error={this.state.error} />;
        } else if (this.state.user) {
            return (
                <div>
                    <NavMenu user={this.state.user} />
                    <Container fluid>
                        {this.props.children}
                    </Container>
                </div>
            );
        } else {
            return null;
        }
    }
}
