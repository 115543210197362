import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './ArticleBreadcrumb.scss';

export class ArticleBreadcrumb extends Component {
    static displayName = ArticleBreadcrumb.name;

    render() {
        if (this.props.breadcrumb && this.props.breadcrumb.length) {
            return (<ul className="article-breadcrumb">
                {this.props.breadcrumb.map(segment =>
                    <li key={segment.title}><Link to={"/articles/" + segment.slug}>{segment.title}</Link></li>
                )}
            </ul>);
        } else {
            return null;
        }
    }
}
