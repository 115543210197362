import React, { Component } from 'react';
import { TocHeaderList } from './TocHeaderList';
import $ from 'jquery';

import './InteractiveToc.scss'

export class InteractiveToc extends Component {
    static displayName = InteractiveToc.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { showGoTop: false };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (window) {
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll() {
        if (this._isMounted) {
            let currentScrollPos = window.pageYOffset;
            if (!this.state.showGoTop && currentScrollPos > 70) {
                this.setState({ showGoTop: true });
            } else if (this.state.showGoTop && currentScrollPos < 70) {
                this.setState({ showGoTop: false });
            }
        }
    }

    componentDidUpdate() {
        $('body').scrollspy({ target: '.table-of-contents-wrapper nav.nav-scroll-spy', offset: 30 });
    }

    componentWillUnmount() {
        this._isMounted = false;
        $('body').scrollspy('dispose');
        window.removeEventListener('scroll', this.handleScroll);
    }

    gotop() {
        window.location.href = '#';
    }

    render() {
        return (
            <details open="open" className="table-of-contents-wrapper">
                <summary className="table-of-contents-wrapper-summary">Sur cette page</summary>
                <nav className="nav-scroll-spy">
                    {this.props.toc &&
                        <TocHeaderList items={this.props.toc}></TocHeaderList>
                    }
                </nav>
                <button className={this.state.showGoTop ? "gotop btn btn-link" : 'd-none'} onClick={() => this.gotop()}>↑ Haut de page</button>
            </details>
        );
    }
}