import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Loading } from './Loading';

export class Menu extends Component {
    static displayName = Menu.name;
    menuAutoScrollTopOffset = 150;

    constructor(props) {
        super(props);
        this.state = { menu: {}, loading: true };
    }

    componentDidMount() {
        const { menu } = this.props;
        this.setState({ menu: menu, loading: false });

        setTimeout(() => this.scrollMenuToSelectedNode());
    }

    componentDidUpdate() {
        setTimeout(() => this.scrollMenuToSelectedNode());
    }

    scrollMenuToSelectedNode() {
        const { path } = this.props;

        var scrollContainer = document.querySelector(".menu-scrollable");
        var target = document.querySelector(".menu-scrollable li[menu-article-id=\"" + path + "\"]");
        var active = document.querySelector(".menu-scrollable li.active");

        if (target && scrollContainer && target !== active) {

            if (active) active.classList.remove("active");
            target.classList.add("active");

            var targetTop = target.getBoundingClientRect().top;
            var containerTop = scrollContainer.getBoundingClientRect().top;
            var containerScrollTop = scrollContainer.scrollTop;
            var scrollTop = Math.max(0, targetTop + containerScrollTop - containerTop - this.menuAutoScrollTopOffset);
            scrollContainer.scrollTo(0, scrollTop);
        }
    }

    renderMenu = function (menu, path) {
        return <ul className="list-unstyled">
            {menu.children.map(child => {
                if (child.node.type === 2) {
                    return <li key={child.node.id} menu-article-id={child.node.slug}>
                        <Link to={`/articles/${child.node.slug}`}>{child.node.title}</Link>
                    </li>
                } else if (child.node.type === 1 && child.children) {
                    var open = path.indexOf(child.node.slug) === 0;
                    return <details open={open ? "open" : ""} key={child.node.id}>
                        <summary>{child.node.title}</summary>
                        {this.renderMenu(child, path)}
                    </details>
                } else {
                    return null;
                }
            }
            )}
        </ul>;
    }

    render() {
        const { path } = this.props;

        if (this.state.loading) {
            return <Loading />;
        } else if (this.state.menu) {
            return (
                <div className="menu-scrollable p-2">
                    {this.renderMenu(this.state.menu, path)}
                </div>
            );
        } else {
            return null;
        }
    }
}