import React, { Component } from 'react';
import { API } from './../API';
import Error from './Error';

import './ArticleFeedback.scss';

export class ArticleFeedback extends Component {
    static displayName = ArticleFeedback.name;

    article = null;

    constructor(props) {
        super(props);
        this.state = { showForm: false, comments: '', submited: false, upvote: false };
    }

    async componentDidMount() {
        const { article } = this.props;
        this.article = article;
    }

    async componentDidUpdate() {
        const { article } = this.props;
        if (this.article && article && article.id !== this.article.id) {
            this.article = article;
            this.setState({ showForm: false, comments: '', submited: false, upvote: false, error: null });
        }
    }

    showForm(upvote) {
        this.setState({ showForm: true, upvote: upvote });
    }

    onCommentsChange(event) {
        this.setState({ comments: event.target.value });
    }

    async submit(event) {
        event.preventDefault();
        if (this.props.article) {
            var result = await API.createArticleFeeback(this.props.article.id, this.state.upvote, this.state.comments);
            if (result.error) {
                this.setState({ submited: false, error: result.error });
            } else {
                this.setState({ showForm: false, comments: '', submited: true, error: null });
            }
        } else {
            this.setState({ submited: false, error: { message: "Impossible de partager l'article"} });
        }
    }

    abort(event) {
        this.setState({ showForm: false, comments: '', submited: false, upvote: false });
        event.preventDefault();
    }

    render() {
        return (
            <div className="article-feedback card d-print-none">
                <div className="card-body row">
                    <div className="col-8">
                        <h4>Avez-vous trouvé cet article utile ?</h4>
                        <p className="m-0">Vos commentaires nous aident à améliorer notre documentation.</p>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        {this.state.showForm ?
                            (
                                <div className="w-100">
                                    {this.state.error && <Error error={this.state.error} />}
                                    <textarea className="form-control w-100" placeholder="Des commentaires supplémentaires ? (optionnel)"
                                        value={this.state.comments} onChange={(event) => this.onCommentsChange(event)} rows="3" autoFocus></textarea>
                                    <div className="float-right mt-3">
                                        <button className="btn btn-sm btn-link" onClick={(event) => this.abort(event)}>Annuler</button>
                                        <button className="btn btn-sm btn-primary " onClick={(event) => this.submit(event)}>Enregistrer le vote</button>
                                    </div>
                                </div>
                            )
                            :
                            this.state.submited ?
                                (
                                    <div className="align-self-center">
                                        Merci
                                        {this.state.upvote ?
                                            <i className="bi bi-emoji-smile ml-2"></i> :
                                            <i className="bi bi-emoji-frown ml-2"></i>
                                        }
                                    </div>
                                )
                                :
                                (

                                    <div className="align-self-center">
                                        <i className="bi bi-hand-thumbs-up vote upvote" onClick={() => this.showForm(true)} title="Oui"></i>
                                        <i className="bi bi-hand-thumbs-down vote downvote" onClick={() => this.showForm(false)} title="Non"></i>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        );
    }
}