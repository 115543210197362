import React, { Component } from 'react';
import { Avatar } from './Avatar';

import './UserStatus.scss';

export class UserStatus extends Component {
    static displayName = UserStatus.name;

    constructor(props) {
        super(props);
        this.state = { darkmode: false };
    }

    componentDidMount() {
        this.initDarkMode();
    }

    initDarkMode() {
        var isDarkMode = this.isDarkMode();
        this.setState({ darkmode: isDarkMode });
        if (isDarkMode) {
            document.documentElement.classList.add('darkmode');
            document.documentElement.classList.remove('lightmode');
        } else {
            document.documentElement.classList.remove('darkmode');
            document.documentElement.classList.add('lightmode');
        }
    }

    isDarkMode() {
        return window.localStorage.getItem('darkmode') === 'true';
    }

    toggleDarkmode(e) {
        e.stopPropagation();
        e.preventDefault();
        if (this.isDarkMode()) {
            this.setState({ darkmode: false });
            document.documentElement.classList.remove('darkmode');
            document.documentElement.classList.add('lightmode');
            window.localStorage.removeItem('darkmode');
        } else {
            this.setState({ darkmode: true });
            document.documentElement.classList.add('darkmode');
            document.documentElement.classList.remove('lightmode');
            window.localStorage.setItem('darkmode', true);
        }
        return false;
    }

    render() {
        return (
            <div className="user-status float-right">
                <div className="dropdown">
                    <div className="nav-link dropdown-toggle cursor-pointer" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       <Avatar user={ this.props.user } />
                    </div>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <div className="px-3 py-2">
                            <div className="mb-2">
                                <Avatar user={this.props.user} />
                            </div>
                            <div className="text-center">
                                <div>{ this.props.user.name }</div>
                                <div className="text-muted">{this.props.user.upn}</div>
                            </div>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="darkmode-toggle-button px-4 cursor-pointer" onClick={(e) => this.toggleDarkmode(e)}>
                            {this.state.darkmode ?
                                <i className="bi bi-toggle-on mr-2" style={{ color: 'green' }}></i> :
                                <i className="bi bi-toggle-off mr-2" style={{ color: 'gray' }}></i>
                            }
                            <span>Mode sombre</span>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item cursor-pointer">
                            <i className="bi bi-power mr-3"></i>
                            <a href="/users/logout" className="text-danger">Déconnexion</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
