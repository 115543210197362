function createError(status, message) {
    return { error: { status, message } };
}

async function getResult(url, options) {
    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        return { data: data };
    } else {
        return createError(response.status);
    }
}

export const API = {
    getUserSession: async () => {
        try {
            return await getResult('/api/session');
        } catch (e) {
            return createError(undefined, "Impossible d'identifier l'utilisateur connecté");
        }
    },
    getBooks: async () => {
        try {
            return await getResult('/api/books');
        } catch (e) {
            return createError(undefined, "Impossible de récupérer la liste des livres");
        }
    },
    getArticleById: async (id) => {
        try {
            return await getResult('/api/articles/' + encodeURIComponent(id));
        } catch (e) {
            return createError(undefined, "Impossible de récupérer l'article");
        }
    },
    getArticleBySlug: async (path) => {
        try {
            return await getResult('/api/articlesBySlug?slug=' + encodeURIComponent(path));
        } catch (e) {
            return createError(undefined, "Impossible de récupérer l'article");
        }
    },
    search: async (query, offset, limit, service) => {
        try {

            var params = new URLSearchParams('');
            params.set('query', query);
            params.set('offset', offset);
            params.set('limit', limit);
            if (service && service.length) params.set('services', [service]);            

            return await getResult('/api/search?' + params.toString());
        } catch (e) {
            return createError(undefined, "Impossible d'effectuer la recherche");
        }
    },
    getLastArticles: async (count) => {
        try {
            return await getResult('/api/lastArticles?count=' + encodeURIComponent(count));
        } catch (e) {
            return createError(undefined, "Impossible de récupérer les articles");
        }
    },
    createArticleFeeback: async (articleId, isPositive, comments) => {
        try {
            return await getResult('/api/articles/' + encodeURIComponent(articleId) + '/feedbacks/', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ isPositive, comments })
            });
        } catch (e) {
            return createError(undefined, "Impossible d'enregistrer l'évaluation");
        }
    },
    createEvent: async (type, articleId) => {
        try {
            return await getResult('/api/articles/' + encodeURIComponent(articleId) + '/events/' + encodeURIComponent(type), {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        } catch (e) {
            return createError(undefined, "Impossible d'enregistrer l'évènement");
        }
    }
};