import React, { Component } from 'react';
import { Toast, ToastBody } from 'reactstrap';
import { UserSessionService } from './../UserSessionService';
import { API } from './../API';

import './ArticleActions.scss';

export class ArticleActions extends Component {
    static displayName = ArticleActions.name;

    toastHandle = null;

    constructor(props) {
        super(props);
        this.state = { showToast: false, toastMessage: '' };
    }

    showToast(message, hideAfter = 3000) {
        clearTimeout(this.toastHandle);
        this.setState({ showToast: true, toastMessage: message });
        this.toastHandle = setTimeout(() => {
            this.setState({ showToast: false, toastMessage: '' });
        }, hideAfter);
    }

    async copyUrl() {
        if (this.props.article) {
            var href = window.location.href;
            var url = href.substring(0, href.indexOf('/articles/')) + '/share/' + this.props.article.id;
            navigator.clipboard.writeText(url);
            this.showToast("Lien copié dans le presse papier !");
            await API.createEvent("share", this.props.article.id);
        } else {
            alert("Impossible de partager l'article");
        }
    }

    async print() {
        if (this.props.article) {
            window.print();
            await API.createEvent("print", this.props.article.id);
        } else {
            alert("Impossible d'imprimer l'article");
        }
    }

    render() {
        var user = UserSessionService.get();
        return (
            <div className="article-actions d-print-none">
                <div className="d-flex flex-nowrap">
                    <span className="article-actions-item">
                        <button className="btn btn-link" onClick={() => this.copyUrl()} title="Partager">
                            <i className="bi bi-reply bi-flipped"></i>
                            <span className="d-none d-sm-inline-block">Partager</span>
                        </button>
                    </span>
                    <span className="article-actions-item">
                        <button className="btn btn-link" onClick={() => this.print()} title="Imprimer">
                            <i className="bi bi-printer"></i>
                            <span className="d-none d-sm-inline-block">Imprimer</span>
                        </button>
                    </span>
                    {user && user.isXelya &&
                        <span className="article-actions-item">
                            <a href={this.props.article.editUrl} target="_blank" rel="noopener noreferrer" title="Modifier">
                                <i className="bi bi-pencil"></i>
                                <span className="d-none d-sm-inline-block">Modifier</span>
                            </a>
                        </span>
                    }
                </div>
                {this.state.showToast && this.state.toastMessage &&
                    <div className="toast-container">
                        <Toast isOpen={this.state.showToast}>
                            <ToastBody className="bg-dark">{this.state.toastMessage}</ToastBody>
                        </Toast>
                    </div>
                }
            </div>
        );
    }
}