import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { Link } from 'react-router-dom';
import { matchPath, withRouter } from "react-router";
import { UserStatus } from './UserStatus';
import logo from './../content/logo.svg';
import './NavMenu.scss';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);

        const search = new URLSearchParams(window.location.search).get("q") || '';
        this.state = { collapsed: true, isSearch: false, search, extSearch: '' };
    }

    getIsSearch() {
        return !!matchPath(this.props.location.pathname, { path: '/search' });
    }

    componentDidMount() {
        this.setState({ isSearch: this.getIsSearch() });
        if (this.state.isSearch) {
            const search = new URLSearchParams(window.location.search).get("q") || '';
            this.setState({ search });
        }
    }

    componentDidUpdate() {
        var isSearch = this.getIsSearch();
        if (this.state.isSearch !== isSearch) {
            this.setState({ isSearch });
            if (isSearch) {
                const search = new URLSearchParams(window.location.search).get("q") || '';
                this.setState({ extSearch: '', search });
            } else {
                this.setState({ search: '' });
            }
        }
    }

    toggleNavbar() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    onChange(event) {
        if (this.state.isSearch) {
            this.setState({ search: event.target.value });
        } else {
            this.setState({ extSearch: event.target.value });
        }
    }

    search(event) {
        event.preventDefault();

        if (this.state.isSearch) {
            var service = new URLSearchParams(window.location.search).get("s") ?? ""; 
            this.props.history.push('/search?q=' + encodeURIComponent(this.state.search) + '&s=' + encodeURIComponent(service));
        } else {
            this.props.history.push("/search?q=" + encodeURIComponent(this.state.extSearch));
        }
    }

    clear(event) {
        this.setState({ search: '', extSearch: '' });
        if (this.getIsSearch()) this.props.history.push("/search");
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-md px-0" dark>
                    <Container fluid>
                        <NavbarBrand tag={Link} to="/" className="mx-3">
                            <img src={logo} alt="Xelya Docs" className="logo" />
                            <span className="pl-3 text-light">| Docs</span>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <UserStatus user={this.props.user} />
                            <form onSubmit={(event) => this.search(event)} className="w-50 w-lg-25 mx-auto" style={{ 'maxWidth': '400px' }}>
                                <div className="input-group search-input-group">
                                    <input name="q" placeholder="Rechercher ..." autoFocus className="form-control search-input"
                                        value={this.state.isSearch ? this.state.search : this.state.extSearch}
                                        onChange={(event) => this.onChange(event)} />
                                    <div className="input-group-append">
                                        {(this.state.isSearch ? this.state.search : this.state.extSearch) &&
                                            <button className="btn btn-link search-reset" type="reset" onClick={(event) => this.clear(event)}>
                                                <i className="bi bi-x-circle-fill"></i>
                                            </button>
                                        }
                                        <button className="btn search-btn" type="submit"><i className="bi bi-search"></i></button>
                                    </div>
                                </div>
                            </form>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

export default withRouter(NavMenu);