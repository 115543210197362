import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Books } from './components/Books';
import { Article } from './components/Article';
import { GoToArticle } from './components/GoToArticle';
import { Search } from './components/Search';
import Error from './components/Error';

import './App.scss'
import './dark-mode.scss'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Books} />
                    <Route path='/search' component={Search} />
                    <Route path='/articles/:path+' component={Article} />
                    <Route path='/share/:id' component={GoToArticle} />
                    <Route><Error error={{ status: 404 }} /></Route>
                </Switch>
            </Layout>
        );
    }
}