import React, { Component } from 'react';
import { Menu } from './Menu';
import { Loading } from './Loading';
import { MarkdownViewer } from './MarkdownViewer';
import { InteractiveToc } from './InteractiveToc';
import { ArticleBreadcrumb } from './ArticleBreadcrumb';
import { ArticleBanner } from './ArticleBanner';
import { ArticleActions } from './ArticleActions';
import { ArticleFeedback } from './ArticleFeedback';
import { ArticleNav } from './ArticleNav';
import { Link } from "react-router-dom";
import { API } from './../API';
import Error from './Error';

import './Article.scss';

export class Article extends Component {
    static displayName = Article.name;
    path = '';
    root = '';

    constructor(props) {
        super(props);
        this.state = { article: {}, loading: true };
    }

    async componentDidMount() {
        const { path } = this.props.match.params;
        this.path = path;
        this.root = path.split('/')[0];
        await this.populateData();
        this.ensureUrlReplaced();
    }

    async componentDidUpdate() {
        const { path } = this.props.match.params;

        if (this.path !== path && path !== this.state.article.article.slug) {
            this.path = path;
            await this.populateData();
            this.ensureUrlReplaced();
            window.scrollTo(0, 0);
        }

        // browser is not scrolling to the anchor probably because we load content asynchronously
        const hash = window.location.hash.substring(1);
        if (hash && hash.length && hash !== document['data-hash-scrolled']) {
            setTimeout(function () {
                document['data-hash-scrolled'] = hash;
                var target = document.getElementById(hash);
                if (target) target.scrollIntoView();
            });
        }
    }

    ensureUrlReplaced() {
        if (this.state.article && this.state.article.article) {
            var url = "/articles/" + this.state.article.article.slug;
            if (this.props.location.pathname !== url) {
                this.path = this.state.article.article.slug;
                this.props.history.replace(url);
            }
        }
    }    

    renderArticle() {
        if (this.state.article) {
            document.title = this.state.article.article.title + " | Aide Xelya";
        }

        var breadCrumb = [];
        if (this.state.article.breadcrumb && this.state.article.breadcrumb.length) {
            breadCrumb = this.state.article.breadcrumb.slice(0, -1);
        }

        return (
            <>
                <div>
                    <div className="menu-root d-flex justify-content-between text-white d-print-none"
                        style={{
                            'background': this.state.article.menu && this.state.article.menu.node && this.state.article.menu.node.color ?
                                this.state.article.menu.node.color : '#007bff'
                        }}>
                        <div>
                            <Link className="px-3" to="/"><i className="bi bi-arrow-left"></i></Link>
                            <ArticleBreadcrumb breadcrumb={breadCrumb} />
                        </div>
                        <div className="text-white">
                            <ArticleActions article={this.state.article.article} />
                        </div>
                    </div>
                    <div className="d-none d-print-block">
                        <ArticleBreadcrumb breadcrumb={breadCrumb} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2 d-none d-sm-none d-md-block sidebar d-print-none">
                        {this.path && this.path.length > 0 && this.state.article.menu &&
                            <Menu menu={this.state.article.menu} path={this.path} />
                        }
                    </div>
                    <div className="col-12 col-md-8 py-4 px-5 article">
                        <h1 className="pb-3">{this.state.article.article.title}</h1>
                        <ArticleBanner article={this.state.article.article} />
                        <hr />
                        <MarkdownViewer html={this.state.article.article.body} />
                        <div className="my-5"><ArticleFeedback article={this.state.article.article} /></div>
                        <div className="mb-3"><ArticleNav previous={this.state.article.previousArticle} next={this.state.article.nextArticle} /></div>
                    </div>
                    <div className="col-md-2 d-none d-sm-none d-md-block py-3 pl-0 pr-1 interactive-toc d-print-none">
                        <InteractiveToc toc={this.state.article.toc} />
                    </div>
                </div>
            </>
        );
    }

    render() {
        if (this.state.error) return <Error error={this.state.error} />;
        else if (this.state.loading) return <Loading />;
        else if (this.state.article) return this.renderArticle();
        else return null;
    }

    async populateData() {
        var result = await API.getArticleBySlug(this.path);
        if (result.error) {
            this.setState({ error: result.error });
        } else {
            this.setState({ article: result.data, loading: false, error: null });
            await API.createEvent("view", result.data.article.id);
        }
    }
}
